<template>
  <div class="page">
    <Navbar mode="solid" class="fixed top-0 left-0"/>
    <main class="page-content">
      <div class="page-container">
        <h2 class="page-title">
          {{ $t("login.title") }}
        </h2>
        <form
            @submit.prevent="loginSubmit()"
            method="post"
            action=""
            :novalidate="true"
        >
          <InputText
              :label="`${$t('login.input_1.label')}`"
              :placeholder="`${$t('login.input_1.placeholder')}`"
              :disabled="loading"
              type="email"
              name="email"
              @blur="$v.form.email.$touch()"
              :errorMsg="emailErrMsg"
              :showErrMsg="$v.form.email.$error"
              v-model="form.email"
              class="login-input"
          />

          <InputText
              :label="`${$t('login.input_2.label')}`"
              :placeholder="`${$t('login.input_2.placeholder')}`"
              :disabled="loading"
              type="password"
              :errorMsg="passwordErrMsg"
              name="password"
              :showErrMsg="$v.form.password.$error"
              @blur="$v.form.password.$touch()"
              v-model="form.password"
              class="login-input"
          />
          <router-link to="/forgot-password" class="btn-link ml-auto" v-t="'cta.forgot_password'"/>
          <ButtonPrimary
              type="submit"
              :text="`${$t('login.button_2')}`"
              id="submit"
              :loading="loading"
          />
          <div class="flex justify-center w-full mt-6 font-sans text-sm">
            <span class="text-sm text-black-base">{{ $t("signup.text") }}</span>
            <router-link class="btn-link ml-2 mr-auto" :to="`/signup?lang=${lang}`">
              {{ $t("login.button_3") }}
            </router-link>
          </div>
        </form>
      </div>

    </main>
    <BottomBar class="layout-bar"/>
  </div>
</template>

<script>
import BottomBar from "@/components/layout/BottomBar";
import {mapActions, mapGetters} from "vuex";
import {email, required} from "vuelidate/lib/validators";

export default {
  components: {
    BottomBar,
  },
  name: "Login.vue",
  methods: {
    ...mapActions("auth", ["login"]),
    async loginSubmit() {
      this.loading = true;
      try {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return false;
        }
        const res = await this.login(this.form);
        if (!res) return false;
        this.loading = false;
        localStorage.setItem("is_login", "true");

        if (this.$route.query.redirect) {
          await this.$router.push(this.$route.query.redirect);

        } else {
          //Redirect back to homepage after login
          this.$router.push({path: "/", query: {lang: "en"}});
        }
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "error",
          text: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loading: false,
      lang: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    emailErrMsg() {
      let msg;
      if (!this.$v.form.email.email) {
        msg = this.$t("login.input_1.error_1");
      }
      if (!this.$v.form.email.required) {
        msg = this.$t("login.input_1.error_2");
      }
      return msg;
    },
    passwordErrMsg() {
      let msg;
      if (!this.$v.form.password.required) {
        msg = this.$t("login.input_2.error");
      }
      return msg;
    },
  },
  created() {
    //If logged in get redirected to homepage, when trying to go to login page
    if (this.isAuthenticated) this.$router.push({path: "/", params: {lang: "en"}});
  }
}
</script>

<style scoped>
.page-content {
  @apply pb-24 sm:pb-0 px-4 py-12 md:px-12 mt-20;
}

.page-container {
  @apply w-full max-w-md;
  @apply relative transform -translate-x-1/2 left-1/2;
}

.page-title {
  @apply font-sans text-2xl font-semibold text-black-base;
  @apply mb-8;
}

.login-input {
  @apply mb-4;
}

.layout-bar {
  @apply fixed bottom-0 w-full sm:hidden;
}

.btn-link {
  @apply font-sans font-semibold text-right text-sm text-teal-dark hover:text-teal-base;
  @apply block mb-6;
  @apply transition duration-300 ease-in-out;
}

#submit {
  @apply w-full;
}
</style>